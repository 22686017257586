
import { Vue } from 'vue-class-component'
export default class Login extends Vue {
  readonly OSS: string = process.env.VUE_APP_OSS

  received: boolean = false
  played: boolean = false
  timing: number = 30
  openId?: string = ''
  guideVideo?: HTMLVideoElement
  promptType: number = 1
  promptVisible: boolean = false

  onPlayVideo (): void {
    if (this.guideVideo) {
      this.guideVideo.play()
      this.played = true
    }
    this.$DR.pageReportFn({ subModule: 'HGXM_GuidePage_Play' })
  }

  async onReceiveBonus (): Promise<void> {
    if (!this.received && this.timing === 0 && this.openId) {
      const res = await this.$api.user.taskAward(this.openId)
      if (res.code === 1000) {
        this.received = true
        this.promptType = 0
        this.promptVisible = true
      } else {
        this.$toast(res.message)
      }
    }
    this.$DR.pageReportFn({ subModule: 'HGXM_GuidePage_ReceiveBonus' })
  }

  async getFindTaskAward (): Promise<void> {
    if (this.openId) {
      const res = await this.$api.user.findTaskAward(this.openId)
      if (res.code === 1000) {
        this.received = res.data === 1
        if (this.received) {
          this.promptType = 1
          this.promptVisible = true
        }
      }
    }
  }

  created (): void {
    const searchParams: URLSearchParams = new URLSearchParams(window.location.search)
    if (searchParams.get('openId')) {
      this.openId = searchParams.get('openId') as string
    }
    this.getFindTaskAward()
  }

  mounted (): void {
    this.guideVideo = this.$refs.guideVideo as HTMLVideoElement
    this.guideVideo.addEventListener('timeupdate', (e: Event) => {
      if (e.target && this.timing > 0) {
        this.timing = 30 - Math.floor((e.target as HTMLVideoElement).currentTime)
      }
    }, false)
    this.guideVideo.addEventListener('ended', () => {
      this.played = false
    }, false)
  }
}
