<template>
  <div class="guide">
    <video
      ref="guideVideo"
      :src="OSS + '/video/1600139975621.mp4'"
      autoplay="autoplay"
      webkit-playsinline="true"
      playsinline="true"
      x5-video-player-type="h5"
      x5-video-player-fullscreen="true"
      preload="auto"
      :poster="OSS + '/image/1600151627316.jpg'"
      class="guide-video">您的浏览器不支持 video 标签</video>
    <div class="timing">{{ timing }}s</div>
    <button
      v-if="!received && !played"
      class="play"
      @click="onPlayVideo"/>
    <button
      :class="{
        'receive': true,
        'disabled': received || timing > 0
      }"
      @click="onReceiveBonus">{{ timing === 0 ? '领取红包' : '观看30s后，可领红包' }}</button>
    <transition name="v-prompt-fade">
      <div
        v-show="promptVisible"
        class="prompt-modal">
        <img
          src="@/assets/images/icons/arrow.png"
          class="arrow">
        <img
          v-if="promptType === 0"
          src="@/assets/images/icons/red-envelope.png"
          class="red-envelope">
        <div class="prompt">{{ promptType === 0 ? '领取微信红包成功，\n关闭页面返回“惠购小美”公众号\n查看金额！' : '你已经领取过任务红包了哦！\n关闭页面返回公众号！' }}</div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component'
export default class Login extends Vue {
  readonly OSS: string = process.env.VUE_APP_OSS

  received: boolean = false
  played: boolean = false
  timing: number = 30
  openId?: string = ''
  guideVideo?: HTMLVideoElement
  promptType: number = 1
  promptVisible: boolean = false

  onPlayVideo (): void {
    if (this.guideVideo) {
      this.guideVideo.play()
      this.played = true
    }
    this.$DR.pageReportFn({ subModule: 'HGXM_GuidePage_Play' })
  }

  async onReceiveBonus (): Promise<void> {
    if (!this.received && this.timing === 0 && this.openId) {
      const res = await this.$api.user.taskAward(this.openId)
      if (res.code === 1000) {
        this.received = true
        this.promptType = 0
        this.promptVisible = true
      } else {
        this.$toast(res.message)
      }
    }
    this.$DR.pageReportFn({ subModule: 'HGXM_GuidePage_ReceiveBonus' })
  }

  async getFindTaskAward (): Promise<void> {
    if (this.openId) {
      const res = await this.$api.user.findTaskAward(this.openId)
      if (res.code === 1000) {
        this.received = res.data === 1
        if (this.received) {
          this.promptType = 1
          this.promptVisible = true
        }
      }
    }
  }

  created (): void {
    const searchParams: URLSearchParams = new URLSearchParams(window.location.search)
    if (searchParams.get('openId')) {
      this.openId = searchParams.get('openId') as string
    }
    this.getFindTaskAward()
  }

  mounted (): void {
    this.guideVideo = this.$refs.guideVideo as HTMLVideoElement
    this.guideVideo.addEventListener('timeupdate', (e: Event) => {
      if (e.target && this.timing > 0) {
        this.timing = 30 - Math.floor((e.target as HTMLVideoElement).currentTime)
      }
    }, false)
    this.guideVideo.addEventListener('ended', () => {
      this.played = false
    }, false)
  }
}
</script>

<style lang="scss" scoped>
.guide {
  height: 100vh;
  position: relative;
  .guide-video {
    width: 100%;
    height: 100%;
    background-color: #272727;
  }
  .timing {
    width: 60px;
    height: 28px;
    font-size: 15px;
    color: #fff;
    background-color: rgba(#fff, 0.1);
    border-radius: 14px;
    line-height: 28px;
    text-align: center;
    position: absolute;
    top: 15px;
    right: 15px;
  }
  .play {
    width: 66px;
    height: 66px;
    background-image: url('~@/assets/images/icons/play.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 66px 66px;
    background-color: transparent;
    border: 0;
    outline: none;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
  }
  .receive {
    width: 320px;
    height: 48px;
    font-size: 17px;
    color: #fff;
    background-color: #f68733;
    border-radius: 24px;
    border: 0;
    outline: none;
    line-height: 48px;
    transform: translateX(-50%);
    position: absolute;
    left: 50%;
    bottom: 30px;
    &:active {
      background-color: rgba(#f68733, 0.7)
    }
    &.disabled {
      background-color: #ccc;
      &:active {
        background-color: rgba(#ccc, 0.7);
      }
    }
  }
  .prompt-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    margin: 0;
    background-color: rgba(0, 0, 0, 0.8);
    .arrow {
      display: block;
      width: 24px;
      height: 24px;
      margin: 30px 0 0 30px;
    }
    .red-envelope {
      display: block;
      width: 59px;
      height: 65px;
      margin: 80px auto 0 auto;
    }
    .prompt {
      font-size: 17px;
      font-weight: 500;
      color: #fff;
      white-space: pre-wrap;
      line-height: 25px;
      text-align: center;
      margin-top: 30px;
    }
  }
}
.v-prompt-fade-enter-active {
  animation: prompt-fade-in 300ms;
}

.v-prompt-fade-leave-active {
  animation: prompt-fade-out 300ms;
}

@keyframes prompt-fade-in {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes prompt-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 0;
  }
}
</style>
